export default [{ // 车商备案
  path: '/dealer/index',
  component: resolve => import(/* webpackChunkName: "dealerManage" */ '../Index.vue').then(resolve),
  meta: {
    title: '备案车商',
    nav: '/dealer/index',
  },
}, { // 创建备案车商
  path: '/dealer/addDealer',
  component: resolve => import(/* webpackChunkName: "dealerManage" */ '../AddDealer.vue').then(resolve),
  meta: {
    title: '创建备案车商',
    nav: '/dealer/addDealer',
  },
}, { // 编辑备案车商
  path: '/dealer/editDealer',
  component: resolve => import(/* webpackChunkName: "dealerManage" */ '../EditDealer.vue').then(resolve),
  meta: {
    title: '编辑备案车商',
    nav: '/dealer/editDealer',
  },
}, { // 车商详情
  path: '/dealer/detail',
  component: resolve => import(/* webpackChunkName: "dealerManage" */ '../Detail.vue').then(resolve),
  meta: {
    title: '车商详情',
    nav: '/dealer/detail',
  },
}];
